import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppInfo } from '@capacitor/app';

@Injectable({
    providedIn: 'root',
})
export class OriginService {
    private nativeAppMapping = {
        'ch.woonig.tenant': 'woonig.app',
        'ch.woonig.tenant.test': 'test.woonig.app',
        'ch.woonig.tenant.cip': 'ci-platform.app',
        'ch.woonig.tenant.emeria': 'linkathome.app',
        'ch.woonig.tenant.dl': 'dl.woonig.app',
    };

    private urlMapping = {
        'https://portal.dl.woonig.app': 'dl.woonig.app',
        'https://test.portal.dl.woonig.app': 'dl.woonig.app',
        'https://test.portal.ci-platform.app': 'ci-platform.app',
        'https://test.mobile.ci-platform.app': 'ci-platform.app',
        'https://mobile.ci-platform.app': 'ci-platform.app',
        'https://portal.ci-platform.app': 'ci-platform.app',
        'https://mobile.linkathome.app': 'linkathome.app',
        'https://portal.linkathome.app': 'linkathome.app',
    };

    // e.g. for key postfixes
    private whiteLabelMapping = {
        'linkathome.app': 'linkathome',
    };

    public pluginReady = false;
    public origin: string = null;
    public whiteLabelKey: string = null;

    async initialize(appInfo: AppInfo): Promise<void> {
        if (appInfo.id) {
            this.origin = this.nativeAppMapping[appInfo.id || 'ch.woonig.tenant'];
        } else if (!Capacitor.isNativePlatform()) {
            this.origin = this.urlMapping[window.location.origin] || 'woonig.app';
        }

        this.whiteLabelKey = this.whiteLabelMapping[this.origin] || null;
        this.pluginReady = true;
    }
}
